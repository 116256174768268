// import React, { useState } from "react";
import Footer from "../components/Footer";
import useForm from "../customs/useForm";
import { signupUrl } from "../utils/url";

// import { IoIosEye, IoMdEyeOff } from "react-icons/io";

const Signup = () => {
  const initialState = {
    fullname: "",
    email: "",
    password: "",
    phone: "",
  };
  const { handleChange, handleBlur, errors, values, handleSubmit } =
    useForm(initialState);

  let data = {
    email: values.email,
    fullName: values.fullname,
    phoneNumber: values.phone,
    password: values.password,
  };
  // const [passwordIsVisible, setPasswordIsVisible] = useState<Boolean>(false);

  // const makePasswordVisible = () => {
  //   onPasswordChangeHandler("string");
  //   setPasswordIsVisible(true);
  // };
  // const makePasswordInvisible = () => {
  //   onPasswordChangeHandler("password");
  //   setPasswordIsVisible(false);
  // };
  return (
    <>
      <section className="section_register">
        <form onSubmit={(e) => handleSubmit(e, signupUrl, data)}>
          <h1>register</h1>
          <p className="text-2xl text-red-300">{errors.fullname}</p>

          <input
            placeholder="name"
            name="fullname"
            onChange={handleChange}
            onBlur={handleBlur}
            style={{
              border: errors.fullname ? " 2px solid rgb(231, 76, 60)" : "",
            }}
          />
          <p className="text-2xl text-red-300">{errors.email}</p>

          <input
            placeholder="E-mail"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            style={{
              border: errors.email ? "2px solid rgb(231, 76, 60)" : "",
            }}
          />
          <p className="text-2xl text-red-300">{errors.phone}</p>

          <input
            placeholder="phone number"
            name="phone"
            onChange={handleChange}
            onBlur={handleBlur}
            style={{
              border: errors.phone ? "2px solid rgb(231, 76, 60)" : "",
            }}
          />
          <p className="text-2xl text-red-300">{errors.password}</p>

          <input
            placeholder="Password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            style={{
              border: errors.password ? "2px solid rgb(231, 76, 60)" : "",
            }}
          />
          <button>register</button>
          <div className="section_register--box1">
            <p>already have an account</p>
            <a href="/account/login"> login</a>
          </div>
        </form>
      </section>
      <Footer />
    </>
  );
};

export default Signup;
