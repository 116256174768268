import React from "react";
import {
  IoPersonSharp,
  IoBagCheck,
  IoHeart,
  IoSettings,
  IoLogOut,
} from "react-icons/io5";
import { Ctx } from "../contexts/GlobalContexts";
const ProfileNav = () => {
  const { logout } = Ctx();

  const handleLogout = (e: any) => {
    e.preventDefault();
    logout();
  };
  return (
    <ul>
      <li>
        <a href="/account/profile">
          <IoPersonSharp className="profile-icon" /> account overview
        </a>
      </li>
      <li>
        <a href="/account/orders/open">
          <IoBagCheck className="profile-icon" /> orders
        </a>
      </li>
      <li>
        <a href="/account/wishlist">
          <IoHeart className="profile-icon" /> saved items
        </a>
      </li>
      <li>
        <a href="/account/update/password">
          <IoSettings className="profile-icon" /> change password
        </a>
      </li>
      <li>
        <a href="/#" onClick={handleLogout}>
          <IoLogOut className="profile-icon" /> logout
        </a>
      </li>
    </ul>
  );
};

export default ProfileNav;
