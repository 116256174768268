import React from "react";
import { IoMdBasket } from "react-icons/io";
import { Link } from "react-router-dom";

const Slider = ({
  background,
  h1,
  h2,
  text,
  index,
  curSlide,
  tems,
}: {
  background: string;
  h1: string;
  h2?: string;
  tems?: string;
  text: string;
  index: number;
  curSlide: number;
}) => {
  return (
    <div
      className={`header_box header_box-${index}`}
      style={{ transform: `translateX(${100 * (index - curSlide)}%)` }}
    >
      <div className="header_box--1">
        <h1 className="header_primary">{h1}</h1>
        <h2 className="header_secondary">{h2}</h2>
        <p className="header_text">{text}</p>
        <Link to="/#" className="header_tems">
          {tems}
        </Link>
        <Link to="/products" className="header_btn">
          shop products
          <IoMdBasket />
        </Link>
      </div>
      <div className={index === 0 ? "header_box--2" : ""}>
        <img
          src={background}
          alt="background"
          className={index === 0 ? "background" : `background-${index}`}
        />
      </div>
    </div>
  );
};

export default Slider;
