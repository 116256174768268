import bg1 from "../images/bg1.png";
import bg2 from "../images/bg2.png";
import bg3 from "../images/bg3.png";
import bg4 from "../images/bg4.png";
import bg5 from "../images/bg5.png";
import bg6 from "../images/bg6.png";

// categories images
import fruit from "../images/fruit.png";
import grains from "../images/bagofrice1.jpg";
import drinks from "../images/egusi-2.jpeg";
import oils from "../images/oils.svg";
import tuber from "../images/tuber.jpeg";
import veges from "../images/veges.png";

export const data = [
  {
    bg: bg1,
    h1: "Mile12Delivery",
    h2: `Your best choice for Fresh Farm Produce`,
    text: ` Order Fresh Yams and other farm produce from your Comfort Zone and
        have It Delivered to your Doorstep (Lagos Island).`,
  },
  {
    bg: bg2,
    h1: "Quick Delivery",
    h2: ``,
    tems: `Terms and conditions apply`,
    text: `Fast free shipping around Lagos 
    on all our products right at your
    comfort zone. We offer standard, easy purchase
    and an option for return on our
    products.`,
  },
  {
    bg: bg3,
    h1: "Flexible Payments",
    h2: ``,
    text: `You can pay online or on delivery to
    your preferred location using credit 
    cards or cash with our easy-to-use
    payment implementation.`,
  },
  {
    bg: bg4,
    h1: "Order Anything",
    h2: ``,
    text: ` You can order anythng fresh farm
    produce randing from yams, rice,
    vegetables, fruits, egusi, ogbonon
    and yes, you can also get cosmetics
    and deodorants from our store. `,
  },
  {
    bg: bg5,
    h1: "Free Delivery",
    h2: ``,
    tems: `Terms and conditions apply`,

    text: `We also offer free delivery to your
    first purchase to anywhere on
    Lagos Island`,
  },
  {
    bg: bg6,
    h1: "Mobile App",
    tems: `Terms and conditions apply`,
    h2: ``,
    text: ` You can also reach us on our mobile app through the app store for ios and google play store on android.`,
  },
];

export const categoriesData = [
  {
    id: 1,
    img: fruit,
    href: "/products?category=fruits",
    title: "fruits",
  },
  {
    id: 2,
    img: grains,
    href: "/products?category=african kitchen",
    title: "Grains",
  },
  {
    id: 3,
    img: drinks,
    href: "/products?category=drinks",
    title: "African Food",
  },
  {
    id: 4,
    img: oils,
    href: "/products?category=groceries",
    title: "oils",
  },
  {
    id: 5,
    img: tuber,
    href: "/products?category=cosmetics and deodorants",
    title: "Tuber Crops",
  },
  {
    id: 6,
    img: veges,
    href: "/products?category=vegetables",
    title: "vegetables",
  },
];
