import React from "react";
import { IoHeart } from "react-icons/io5";

const EmptySaved = () => {
  return (
    <div className="section_order--box-2">
      <IoHeart className="order" />
      <p>You have no saved items</p>
      <a href="/products"> continue shopping</a>
    </div>
  );
};

export default EmptySaved;
