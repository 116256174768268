import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // Navigate,
} from "react-router-dom";
import { MobileNav, Nav } from "./components/Nav";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Products from "./pages/Products";
import Product from "./pages/Product";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Profile from "./pages/Profile";
import OpenOrders from "./pages/OpenOrders";
import ClosedOrder from "./pages/ClosedOrder";
import Saved from "./pages/Saved";
import Password from "./pages/Password";
import OrderDetails from "./pages/OrderDetails";
import Cart from "./pages/Cart";
import GlobalContexts from "./contexts/GlobalContexts";
import Shipping from "./pages/Shipping";
import Checkout from "./pages/Checkout";
import Success from "./pages/Success";
import LocalContext from "./contexts/LocalContexts";
import About from "./pages/About";
import Contact from "./pages/Contact";
import SearchResults from "./pages/SearchResults";

// ------------------------------ADMIN-------------------------------------------- //
// import { Select, Space } from 'antd';

// const handleChange = (value: string) => {
//   console.log(`selected ${value}`);
// };

// const App: React.FC = () => (
//   <Space wrap>
//     <Select
//       defaultValue="lucy"
//       style={{ width: 120 }}
//       onChange={handleChange}
//       options={[
//         { value: 'jack', label: 'Jack' },
//         { value: 'lucy', label: 'Lucy' },
//         { value: 'Yiminghe', label: 'yiminghe' },
//         { value: 'disabled', label: 'Disabled', disabled: true },
//       ]}
//     />
//     <Select
//       defaultValue="lucy"
//       style={{ width: 120 }}
//       disabled
//       options={[{ value: 'lucy', label: 'Lucy' }]}
//     />
//     <Select
//       defaultValue="lucy"
//       style={{ width: 120 }}
//       loading
//       options={[{ value: 'lucy', label: 'Lucy' }]}
//     />
//     <Select
//       defaultValue="lucy"
//       style={{ width: 120 }}
//       allowClear
//       options={[{ value: 'lucy', label: 'Lucy' }]}
//     />
//   </Space>
// );

// export default App;
function App() {
  return (
    <Router>
      <GlobalContexts>
        <LocalContext>
          <div className="App">
            <ToastContainer className={"text-2xl"} />
            <Nav />
            <MobileNav />
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/" element={<Products />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/products/:slug" element={<Product />} />
              <Route path="/account/login" element={<Login />} />
              <Route path="/account/register" element={<Signup />} />
              <Route path="/account/profile" element={<Profile />} />
              <Route path="/account/orders/open" element={<OpenOrders />} />
              <Route path="/account/orders/closed" element={<ClosedOrder />} />
              <Route path="/account/wishlist" element={<Saved />} />
              <Route path="/account/update/password" element={<Password />} />
              <Route path="/purchase/shipping" element={<Shipping />} />
              <Route path="/purchase/placeorder" element={<Checkout />} />
              <Route path="/products/search" element={<SearchResults />} />
              <Route
                path="/purchase/confirmation/success"
                element={<Success />}
              />
              <Route
                path="/account/orders/details/:id"
                element={<OrderDetails />}
              />

              {/* --------------------------------------------------- */}

              <Route
                path="/admin/home"
                element={<h1 className="underline text-3xl">HOME</h1>}
              />
            </Routes>
          </div>
        </LocalContext>
      </GlobalContexts>
    </Router>
  );
}

export default App;
