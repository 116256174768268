// import React from "react";

const CheckoutCard = (props: any) => {
  return (
    <div className="check">
      <img
        src={`https://omaye-foods-backend-production.up.railway.app/img/productss/${props.img}`}
        alt={props.name}
      />
      <div className="check-cont">
        <p>
          name :<span> ₦{props.name}</span>
        </p>
        <p>
          quantity :<span>{props.qty}</span>
        </p>
        <p>
          price :<span>₦ {props.price}</span>
        </p>
        <p>
          total :<span>₦ {props.total}</span>
        </p>
      </div>
    </div>
  );
};

export default CheckoutCard;
