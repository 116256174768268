// import React from "react";
import EmptySaved from "../components/EmptySaved";
import Footer from "../components/Footer";
import IsLoading from "../components/IsLoading";
import ProfileNav from "../components/ProfileNav";
import SavedItem from "../components/SavedItem";
import { Ctx } from "../contexts/GlobalContexts";
const Saved = () => {
  const { savedItems, savedIsLoading } = Ctx();
  console.log(!savedItems);

  return (
    <>
      {savedIsLoading && <IsLoading isLoading={savedIsLoading} />}
      {savedItems && (
        <section className="section_profile">
          <ProfileNav />
          <div className="section_order--box">
            <div className="section_order--box-1">
              <h2 className="text-4xl">Saved Items</h2>
            </div>
            {!savedItems || savedItems.length <= 0 ? (
              <EmptySaved />
            ) : (
              savedItems.map((item: any) => (
                <SavedItem key={item.id} {...item} />
              ))
            )}
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default Saved;
