import { useEffect, useState } from "react";
import ProductCard from "../components/ProductCard";
import TopProductsSection from "../components/TopProductsSection";
import useFetch from "../customs/useFetch";
import qs, { ParsedQs } from "qs";
import { generalUrl } from "../utils/url";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import { getProductProperties } from "../helpers/productDestructure";
import SearchBar from "../components/SearchBar";

const Products = () => {
  let location = useLocation();
  const [productDetails, setProductDetails] = useState<any>();
  const [queryKey, setQuerykey] = useState<string>("");
  const [queryValue, setQueryValue] = useState<
    string | ParsedQs | string[] | ParsedQs[]
  >("");
  const [page /*, setPage*/] = useState<number>(1);

  useEffect(() => {
    const category = qs.parse(window.location.search);
    if (Object.keys(category).length !== 0) {
      let key = Object.keys(category)[0];
      let string: string[] | string = [];
      let value: string | ParsedQs | string[] | ParsedQs[] =
        Object.values(category)[0]!;
      for (let i = 0; i < key.length; i++) {
        if (i !== 0) string.push(key[i]);
      }
      string = string.join("");
      setQuerykey(string);
      setQueryValue(value);
    }
  }, [location, queryKey, queryValue, page]);

  let url = `${generalUrl}/products/?${queryKey}=${queryValue}&page=${page}`;
  const { datum } = useFetch(url, "token");

  useEffect(() => {
    if (datum) {
      setProductDetails(getProductProperties(datum));
    }
  }, [datum]);
  // if (isLoading) {

  //   return <Loading />;
  // }

  return (
    <>
      <SearchBar />
      <TopProductsSection />
      <section className="products_section">
        {productDetails && (
          <div className=" products_section--container">
            {productDetails.length !== 0 &&
              productDetails.map((data: any) => (
                <ProductCard
                  link={data.slug}
                  key={data.id}
                  name={data.name}
                  price={data.price}
                  description={data.description}
                  coverImage={data.image}
                  category={data.category}
                  id={data.id}
                />
              ))}
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default Products;
