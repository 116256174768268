import Cookies from "js-cookie";
import { createContext, useContext, useState, useEffect } from "react";
import { displaySuccessToast } from "../abstracts/toast";

const context = createContext<any>(null!);

const LocalContext = ({ children }: any) => {
  const [cart, setCart] = useState(
    Cookies.get("cart") ? JSON.parse(Cookies.get("cart")!) : []
  );
  const [subTotal, setSubTotal] = useState(
    Cookies.get("cartTotal") ? JSON.parse(Cookies.get("cartTotal")!) : 0
  );
  const [totalQuantity, setTotalQuantity] = useState(
    Cookies.get("cartQuantity") ? JSON.parse(Cookies.get("cartQuantity")!) : 0
  );

  const onAdd = (product: any, quantity: number) => {
    setSubTotal((prevSubTotal: any) => prevSubTotal + product.price * quantity);
    setTotalQuantity((prevTotalQuantity: any) => prevTotalQuantity + quantity);
    const checkIfItemIsinCart = cart.find(
      (item: any) => item.id === product.id
    );
    if (checkIfItemIsinCart) {
      const itemIndex = cart.findIndex((item: any) => item.id === product.id);
      const newCart = cart.slice();
      const item = newCart[itemIndex];
      item.quantity = item.quantity + quantity;
      item.totalPrice = item.quantity * item.price;
      newCart[itemIndex] = item;
      setCart(newCart);
    } else {
      product.quantity = quantity;
      product.totalPrice = product.price * quantity;
      setCart([...cart, { ...product }]);
    }
    console.log(cart);

    displaySuccessToast("product added to cart successfully");
  };

  const toggleItemQuantity = (id: string, value: string) => {
    const newCart = cart.slice();
    const productToUpdateIndex = newCart.findIndex(
      (item: any) => item.id === id
    );

    const productToUpdate = newCart[productToUpdateIndex];
    if (value === "inc") {
      productToUpdate.quantity += 1;
      productToUpdate.totalPrice =
        productToUpdate.quantity * productToUpdate.price;
      newCart[productToUpdateIndex] = productToUpdate;
      setCart(newCart);
      setTotalQuantity((prev: any) => prev + 1);
      setSubTotal((prev: any) => prev + productToUpdate.price);
    } else if (value === "dec") {
      if (productToUpdate.quantity > 1) {
        productToUpdate.quantity -= 1;
        productToUpdate.totalPrice =
          productToUpdate.quantity * productToUpdate.price;
        newCart[productToUpdateIndex] = productToUpdate;
        setCart(newCart);
        setTotalQuantity((prev: any) => prev - 1);
        setSubTotal((prev: any) => prev - productToUpdate.price);
      } else {
        newCart.splice(productToUpdateIndex, 1);
        setCart(newCart);
        setTotalQuantity((prev: any) => prev - 1);
        setSubTotal((prev: any) => prev - productToUpdate.price);
      }
    } else if (value === "del") {
      newCart.splice(productToUpdateIndex, 1);
      setCart(newCart);
      setTotalQuantity((prev: any) => prev - 1);
      setSubTotal(
        (prev: any) => prev - productToUpdate.price * productToUpdate.quantity
      );
    }
  };

  useEffect(() => {
    Cookies.set("cart", JSON.stringify(cart));
    Cookies.set("cartTotal", JSON.stringify(subTotal));
    Cookies.set("cartQuantity", JSON.stringify(totalQuantity));
  }, [cart, totalQuantity, subTotal]);

  return (
    <context.Provider
      value={{
        onAdd,
        cart,
        subTotal,
        toggleItemQuantity,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const Lctx = () => useContext(context);

export default LocalContext;
