// // -------------------------- HEROKU URLS -----------------------------------//
// export const generalUrl = "https://mile12delivery.herokuapp.com/api/v1";
// export const loginUrl =
//   "https://mile12delivery.herokuapp.com/api/v1/users/login";
// export const signupUrl =
//   "https://mile12delivery.herokuapp.com/api/v1/users/signup";

// export const userUrl = "https://mile12delivery.herokuapp.com/api/v1/users/me";
// export const updateUserUrl = "http://127.0.0.1:3300/api/v1/users/updateme";

// export const updateUserPasswordUrl =
//   "https://mile12delivery.herokuapp.com/api/v1/users/updatemypassword";
// export const bookingsUrl =
//   "https://mile12delivery.herokuapp.com/api/v1/bookings";

// export const cartUrl = "https://mile12delivery.herokuapp.com/api/v1/carts";
// export const savedUrl = "https://mile12delivery.herokuapp.com/api/v1/saved";

// -------------------------- RAILWAY URLS -----------------------------------//
export const generalUrl =
  "https://omaye-foods-backend-production.up.railway.app/api/v1";
export const loginUrl =
  "https://omaye-foods-backend-production.up.railway.app/api/v1/users/login";
export const signupUrl =
  "https://omaye-foods-backend-production.up.railway.app/api/v1/users/signup";

export const userUrl =
  "https://omaye-foods-backend-production.up.railway.app/api/v1/users/me";
export const updateUserUrl =
  "https://omaye-foods-backend-production.up.railway.app/api/v1/users/updateme";

export const updateUserPasswordUrl =
  "https://omaye-foods-backend-production.up.railway.app/api/v1/users/updatemypassword";
export const bookingsUrl =
  "https://omaye-foods-backend-production.up.railway.app/api/v1/bookings";

export const cartUrl =
  "https://omaye-foods-backend-production.up.railway.app/api/v1/carts";
export const savedUrl =
  "https://omaye-foods-backend-production.up.railway.app/api/v1/saved";
