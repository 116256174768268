import React from "react";
import contact from "../assets/images/contact.png";
import Footer from "../components/Footer";
const Contact = () => {
  return (
    <main className="contact">
      <section className="contact__intro">
        <div className=" contact__intro-container">
          <div className="contact__intro-box-1">
            <h2>Information About us</h2>
            <p>
              Mile12delivery is an online store that sells local fresh
              agricultural produce in Lagos Island. We promise the greatest
              customer service and deliver within 24 hours. Mile12delivery will
              always be your best choice for fresh farm produce.
            </p>
            <div className="contact__intro-colorcont">
              <div className="contact__intro-colorbox contact__intro-colorbox-1"></div>
              <div className="contact__intro-colorbox contact__intro-colorbox-2"></div>
              <div className="contact__intro-colorbox contact__intro-colorbox-3"></div>
            </div>
          </div>
          <div className="contact__intro-box-2">
            <h2>Contact Way</h2>
            <div className="contact__intro-details">
              <div className="contact__intro-details-box">
                <div className="contact__intro-color contact__intro-color-1"></div>
                <div className="details-helpp">
                  <p>tel: +234 8147 310 446</p>
                  <p>e-mail: mile12delivery@gmail.com</p>
                  <p>whatsapp: +234 8147 310 446</p>
                </div>
              </div>
              <div className="contact__intro-details-box">
                <div className="contact__intro-color contact__intro-color-2"></div>
                <div className="details-helpp">
                  <p>Support Forum</p>
                  <p>For over 24hr</p>
                </div>
              </div>
              <div className="contact__intro-details-box">
                <div className="contact__intro-color contact__intro-color-3"></div>
                <div className="details-helpp">
                  <p> 11 idado estate.</p>
                  <p> Lekki Lagos.</p>
                </div>
              </div>
              <div className="contact__intro-details-box">
                <div className="contact__intro-color contact__intro-color-4"></div>
                <div className="details-helpp">
                  <p>free standard shipping</p>
                  <p>on first orders</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact__form">
        <div className="contact__form-container">
          <div>
            <h2>get in touch</h2>
            <p>
              You can reach out to mile12delivery at any time with concerns or
              ideas. We guarantee a quick, efficient answer. We appreciate your
              support.
            </p>
            <form>
              <input type="text" placeholder="your name" />
              <input type="email" placeholder="your email" />
              <input
                type="text"
                placeholder="subject"
                className="contact__help"
              />
              <textarea
                placeholder="type your message"
                className="contact__help"
              />
              <button type="submit">send mail</button>
            </form>
          </div>
          <img src={contact} alt="contact" />
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Contact;
