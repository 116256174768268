interface Productslider {
  price: number;
  name: string;
  description: string;
  img: string;
  index: number;
  curSlide: number;
  slug: string;
}
const TopProductsSlider = ({
  price,
  name,
  description,
  img,
  index,
  curSlide,
  slug,
}: Productslider) => {
  return (
    <a
      href={`/products/${slug}`}
      className="section_top-slide"
      style={{ transform: `translateX(${100 * (index - curSlide)}%)` }}
    >
      <div className="section_top-slide--box1">
        <h3>{name}</h3>
        <p>{description}</p>
        <p>₦{price}</p>
      </div>
      <div className="section_top-slide--box2">
        <img
          src={`https://omaye-foods-backend-production.up.railway.app/img/productss/${img}`}
          alt="coke"
        />
      </div>
    </a>
  );
};

export default TopProductsSlider;
