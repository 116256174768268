// import React from "react";
import { useEffect, useState } from "react";
import { getData } from "../abstracts/apiRequests";
import { Ctx } from "../contexts/GlobalContexts";
import { bookingsUrl } from "../utils/url";
import Footer from "../components/Footer";
import ProfileNav from "../components/ProfileNav";
import ContentOrder from "../components/ContentOrder";
import EmptyOrder from "../components/EmptyOrder";

const ClosedOrder = () => {
  const [bookings, setBookings] = useState<any>(null);
  const { userAuth } = Ctx();
  useEffect(() => {
    getData(bookingsUrl, {
      headers: {
        authorization: userAuth,
      },
    })
      .then((data) => {
        const { bookings } = data.data.data;
        const closedOrders = bookings.filter(
          (item: any) => item.isDelivered === true
        );
        setBookings(closedOrders);
      })
      .catch((err) => console.log(err));
  }, [userAuth]);
  return (
    <>
      <section className="section_profile">
        <ProfileNav />
        <div className="section_order--box">
          <div className="section_order--box-1">
            <a href="/account/orders/open"> open orders</a>
            <a href="/account/orders/closed"> closed orders</a>
          </div>
          {bookings && bookings.length === 0 && <EmptyOrder message="closed" />}

          {bookings &&
            bookings.length > 0 &&
            bookings.map((item: any) => (
              <ContentOrder
                key={item.id}
                image={item.order[0].productId.coverImage}
                name={item.order[0].productId.name}
                id={item.transactionReference}
                qty={item.order.length - 1}
                date=""
                bookingId={item.id}
              />
            ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ClosedOrder;
