import { useState } from "react";
import validator from "./validator";
import Cookies from "js-cookie";
import Nprogress from "nprogress";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Ctx } from "../contexts/GlobalContexts";
import { displayErrorToast, displaySuccessToast } from "../abstracts/toast";
import { cartUrl } from "../utils/url";

const useForm = (initialState, location) => {
  const navigateAfterAuthentication = location || "/account/profile";
  const { setisLoggedIn, setUser } = Ctx();

  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBlur = (e) => {
    const { name: fieldName } = e.target;
    const failedFields = validator(values, fieldName);

    setErrors(() => ({
      ...errors,
      [fieldName]: Object.values(failedFields)[0],
    }));
  };

  const handleSubmit = (e, url, data) => {
    e.preventDefault();
    const status = Object.values(values).map((el) => el.length === 0);
    if (status.includes(true)) {
      return displayErrorToast("Please fill in all your inputs");
    }
    Nprogress.start();

    axios
      .post(url, data)
      .then((data) => {
        Nprogress.done();
        Cookies.set("user", JSON.stringify(data.data.data.user), {
          expires: 12,
        });
        Cookies.set("SESSIONID", JSON.stringify(data.data.token), {
          expires: 12,
        });
        setisLoggedIn(true);
        setUser(data.data.data.user);
        // test cart items
        axios.post(
          `${cartUrl}/synccart`,
          {
            cartItems: JSON.parse(Cookies.get("cart")),
            subTotal: JSON.parse(Cookies.get("cartTotal")),
          },
          {
            headers: {
              authorization: `Bearer ${data.data.token}`,
            },
          }
        );

        // end cart test
        Cookies.remove("cart");
        Cookies.remove("cartTotal");
        Cookies.remove("cartQuantity");
        displaySuccessToast("Authenticated successfully");
        navigate(navigateAfterAuthentication, { replace: true });
      })
      .catch((error) => {
        Nprogress.done();
        displayErrorToast(error.response.data.message);
      });
  };
  return { handleChange, handleBlur, errors, values, handleSubmit };
};

export default useForm;
