import { phoneNumberRegex, emailRegex } from "../utils/config";
const validateFullName = (fullname: string, errors: any) => {
  if (!fullname) {
    return (errors.fullname = "Full name cannot be empty");
  } else {
    errors.fullname = "";
  }
};
const validateEmail = (email: string, errors: any) => {
  if (!email) {
    return (errors.email = "Email cannot be empty");
  } else if (!emailRegex.test(email)) {
    return (errors.email = "Please put in a valid email");
  }
  errors.email = "";
};
const validatePhoneNumber = (phone: string, errors: any) => {
  if (!phone) {
    return (errors.phone = "Phone number cannot be empty");
  } else if (!phoneNumberRegex.test(phone)) {
    return (errors.phone = "Please put in a valid phone number");
  }
  errors.phone = "";
};

const validatePassword = (password: string, errors: any) => {
  if (!password) {
    return (errors.password = "Password cannot be empty");
  } else if (password.length < 8) {
    return (errors.password = "Password cannot be less than 8 characters");
  }
  errors.password = "";
};

const validator = (values: any, fieldName: string) => {
  let errors = {};
  switch (fieldName) {
    case "email":
      validateEmail(values.email, errors);
      break;
    case "fullname":
      validateFullName(values.fullname, errors);
      break;
    case "password":
      validatePassword(values.password, errors);
      break;
    case "phone":
      validatePhoneNumber(values.phone, errors);
      break;

    // default:
    //   break;
  }
  return errors;
};
export default validator;
