import { Ctx } from "../contexts/GlobalContexts";
import ProductCard from "../components/ProductCard";

const SearchResults = () => {
  const { searchProducts, search } = Ctx();
  //   console.log(searchProducts);

  return (
    <>
      <div className="search__info">Results for `{search}`</div>
      <section className="products_section">
        {searchProducts && (
          <div className=" products_section--container">
            {searchProducts.length !== 0 &&
              searchProducts.map((data: any) => (
                <ProductCard
                  link={data.slug}
                  key={data.id}
                  name={data.name}
                  price={data.price}
                  description={data.description}
                  coverImage={data.coverImage}
                  category={data.category}
                  id={data.id}
                />
              ))}
          </div>
        )}
      </section>
    </>
  );
};

export default SearchResults;
