import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { cartUrl, generalUrl } from "../utils/url";
import useFetch from "../customs/useFetch";
import {
  IoChevronBack,
  IoChevronForward,
  IoCartOutline,
} from "react-icons/io5";
import ProductCard from "../components/ProductCard";
import Footer from "../components/Footer";
import { Ctx } from "../contexts/GlobalContexts";
import { Lctx } from "../contexts/LocalContexts";
import { postData } from "../abstracts/apiRequests";
import Cookies from "js-cookie";
import NProgress from "nprogress";
import { displaySuccessToast } from "../abstracts/toast";
import { getOneProductProperty } from "../helpers/productDestructure";

const Product = () => {
  const [active, setActive] = useState<boolean>(true);
  const [productDetails, setProductDetails] = useState<any>();

  const [data, setData] = useState(null);
  const { inc, dec, qty, user } = Ctx();
  const { onAdd } = Lctx();
  const { slug } = useParams();
  const { datum: productData } = useFetch(
    `${generalUrl}/products/helper/${slug}`,
    "huyuh"
  );

  // for similar data
  const { datum: similarDatum } = useFetch(
    `${generalUrl}/products?category=${data}&limit=6`
  );

  productDetails && console.log(productDetails);

  useEffect(() => {
    if (productData) {
      const { category } = productData.data.data.product;
      setData(category);
      setProductDetails(getOneProductProperty(productData));
    }
  }, [productData]);
  const activeTrue = () => {
    setActive(true);
  };
  const activeFalse = () => {
    setActive(false);
  };

  const addToCart = (product: any, qty: number) => {
    NProgress.start();
    postData(
      cartUrl,
      {
        productId: product.id,
        price: product.price,
        quantity: qty,
      },
      {
        headers: {
          authorization: `Bearer ${JSON.parse(Cookies.get("SESSIONID")!)}`,
        },
      }
    ).then(() => {
      NProgress.done();
      displaySuccessToast("Product added successfully");
    });
  };

  return (
    <>
      {productDetails && (
        <section className="details_section">
          <img
            src={`https://omaye-foods-backend-production.up.railway.app/img/productss/${productDetails.image}`}
            alt={productDetails.name}
            className="details_section--bg"
          />
          <div className="details_section--cont">
            <div className="details_section--box1">
              <img
                src={`https://omaye-foods-backend-production.up.railway.app/img/productss/${productDetails.image}`}
                alt={productDetails.name}
              />
            </div>
            <div className="details_section--box2">
              <h2>{productDetails.name}</h2>
              <p>{productDetails.description}</p>
              <div>
                <IoChevronBack className="inc mr-3" onClick={dec} />

                <h3>{qty} pieces</h3>
                <IoChevronForward className="inc ml-3" onClick={inc} />
              </div>

              <p>₦ {productDetails.price}</p>
              <button
                onClick={
                  user
                    ? () => addToCart(productDetails, qty)
                    : () => onAdd(productDetails, qty)
                }
              >
                Add to Cart <IoCartOutline />
              </button>
            </div>
          </div>
        </section>
      )}
      <section className="section_explain">
        <div className="section_explain-select">
          <button
            onClick={activeTrue}
            className={active === true ? "btn-bord" : ""}
          >
            Additional information
          </button>
          <button
            onClick={activeFalse}
            className={active !== true ? "btn-bord" : ""}
          >
            reviews
          </button>
        </div>
        {active && (
          <div className="section_explain-container">
            <h2>Product details</h2>
            <ul>
              <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            </ul>
          </div>
        )}
        {!active && (
          <div className="section_explain-reviews">
            <div className="section_explain-reviews--box1">
              <h2>reviews</h2>
              <div>
                <p>martinlutherakuhwa@gmail.com</p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Possimus excepturi corporis asperiores ratione recusandae
                  provident modi laboriosam doloribus, iusto nesciunt commodi
                  distinctio molestiae fuga ullam vero ipsa voluptatem, magnam
                  consequuntur!
                </p>
              </div>
            </div>
            <div className="section_explain-reviews--box2">
              <p>Your review</p>
              <textarea />
              <button>submit</button>
            </div>
          </div>
        )}
      </section>
      <section className="section_similar">
        <div className=" products_section--container">
          {similarDatum &&
            //   @ts-ignore
            similarDatum.data.map((data: any) => (
              <ProductCard
                link={data.slug}
                key={data.id}
                name={data.name}
                price={data.price}
                description={data.description}
                coverImage={data.coverImage}
                category={data.category}
                id={data.id}
              />
            ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Product;
