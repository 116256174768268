import React, { useState, useEffect } from "react";
import useFetch from "../customs/useFetch";
import { generalUrl } from "../utils/url";
import TopProductsSlider from "../components/TopProductsSlider";
import { nextSlide, prevSlide } from "../abstracts/sliderFunctions";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { getProductProperties } from "../helpers/productDestructure";

const TopProductsSection = () => {
  const [topSlideIndex, setTopSlideIndex] = useState<number>(0);
  const [productDetails, setProductDetails] = useState<any>();

  const [id, setId] = useState<any>();

  const { datum } = useFetch(
    `${generalUrl}/products?category=fresh farm produce`,
    "token"
  );

  useEffect(() => {
    const id = setInterval(() => {
      productDetails &&
        nextSlide(topSlideIndex, productDetails, setTopSlideIndex, id);
    }, 6000);
    setId(id);
  }, [topSlideIndex, productDetails]);

  useEffect(() => {
    if (datum) {
      setProductDetails(getProductProperties(datum));
    }
  }, [datum]);
  return (
    <section className="section_top">
      <div className="section_categories--box1">
        <h2>Top products</h2>
      </div>

      <div className="section_top-slider">
        {productDetails &&
          productDetails.map((data: any, i: number) => (
            <TopProductsSlider
              slug={data.slug}
              key={data.id}
              name={data.name}
              description={data.description}
              price={data.price}
              img={data.image}
              index={i}
              curSlide={topSlideIndex}
            />
          ))}
        {productDetails && (
          <div
            className="section_top-slide--btn section_top-slide--btnleft"
            onClick={() =>
              prevSlide(topSlideIndex, productDetails, setTopSlideIndex, id)
            }
          >
            <IoIosArrowBack />
          </div>
        )}
        {productDetails && (
          <div
            className="section_top-slide--btn section_top-slide--btnright"
            onClick={() =>
              nextSlide(topSlideIndex, productDetails, setTopSlideIndex, id)
            }
          >
            <IoIosArrowForward />
          </div>
        )}
      </div>
    </section>
  );
};

export default TopProductsSection;
