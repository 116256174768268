import Footer from "../components/Footer";
import TopProductsSection from "../components/TopProductsSection";
import EmptyCart from "../components/EmptyCart";
import { useNavigate } from "react-router-dom";
import CartContent from "../components/CartContent";
import { Ctx } from "../contexts/GlobalContexts";
import { Lctx } from "../contexts/LocalContexts";
import { displayErrorToast } from "../abstracts/toast";
import Cookies from "js-cookie";
import IsLoading from "../components/IsLoading";
const Cart = () => {
  const navigate = useNavigate();
  const { cartItems, cartIsLoading, userAuth } = Ctx();
  const { cart, subTotal } = Lctx();
  const checkoutHandler = (e: any) => {
    e.preventDefault();
    if (!userAuth)
      return displayErrorToast("Please Login to be able to make a purchase");
    Cookies.set("purchaseCart", JSON.stringify(cartItems));
    navigate("/purchase/shipping");
  };
  if (userAuth) {
    return (
      <>
        {cartIsLoading && <IsLoading isLoading={cartIsLoading} />}
        {cartItems && cartItems.cart.length === 0 && <EmptyCart />}
        <section className="section_cartcont">
          {cartItems &&
            cartItems.cart.length > 0 &&
            cartItems.cart.map((item: any) => (
              <CartContent key={item.idForProduct} {...item} />
            ))}
          {cartItems && cartItems.cart.length > 0 && (
            <div className="cart-price">
              <h2>summary</h2>
              <div>
                <p>price</p>
                <p>₦ {cartItems.subTotal}</p>
              </div>

              <button onClick={checkoutHandler}>proceed to checkout</button>
            </div>
          )}
        </section>
        <TopProductsSection />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        {cart && cart.length === 0 && <EmptyCart />}
        <section className="section_cartcont">
          {cart &&
            cart.length > 0 &&
            cart.map((item: any) => <CartContent key={item.id} {...item} />)}
          {cart && cart.length > 0 && (
            <div className="cart-price">
              <h2>summary</h2>
              <div>
                <p>price</p>
                <p>₦ {subTotal}</p>
              </div>

              <button onClick={checkoutHandler}>proceed to checkout</button>
            </div>
          )}
        </section>
        <TopProductsSection />
        <Footer />
      </>
    );
  }
};

export default Cart;
