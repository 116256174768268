import { useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import useForm from "../customs/useForm";
import { loginUrl } from "../utils/url";

interface locationState {
  from: {
    pathname: string;
  };
}
const Login: React.FC = () => {
  const location = useLocation();
  const { from } = (location.state as locationState) || "/account/profile";

  const initialState = {
    email: "",
    password: "",
  };

  const { handleChange, handleBlur, errors, values, handleSubmit } = useForm(
    initialState,
    from
  );

  let data = {
    email: values.email,
    password: values.password,
  };

  return (
    <>
      <section className="section_register">
        <form onSubmit={(e) => handleSubmit(e, loginUrl, data)}>
          <h1>login</h1>
          <p className="text-2xl text-red-300">{errors.email}</p>
          <input
            placeholder="E-mail"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            style={{
              border: errors.email ? "2px solid rgb(231, 76, 60)" : "",
            }}
          />
          <p className="text-2xl text-red-300">{errors.password}</p>

          <input
            placeholder="Password"
            name="password"
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            style={{
              border: errors.password ? "2px solid rgb(231, 76, 60)" : "",
            }}
          />
          <button>login</button>
          <div className="section_register--box1">
            <p>don't have an account</p>
            <a href="/account/register"> register</a>
          </div>
        </form>
      </section>
      <Footer />
    </>
  );
};

export default Login;
