const ContentOrder = ({
  image,
  name,
  id,
  qty,
  date,
  bookingId,
}: {
  image?: string;
  name?: string;
  id?: string;
  qty?: number;
  date?: string;
  bookingId?: string;
}) => {
  return (
    <div className="section_order">
      <img
        src={`https://omaye-foods-backend-production.up.railway.app/img/productss/${image}`}
        alt="product"
      />
      <div>
        <p>{name}</p>
        <p className="p">order {id}</p>
        <p className="p">{qty} more item(s)</p>
        {/* <p>On 25-04-2022</p> */}
      </div>
      <a href={`/account/orders/details/${bookingId}`}>see details</a>
    </div>
  );
};

export default ContentOrder;
