import NProgress from "nprogress";
import { IoAddOutline, IoRemoveOutline } from "react-icons/io5";
import { patchData } from "../abstracts/apiRequests";
import { displaySuccessToast } from "../abstracts/toast";
import { Ctx } from "../contexts/GlobalContexts";
import { Lctx } from "../contexts/LocalContexts";
import { cartUrl } from "../utils/url";

const CartContent = (props: any) => {
  const { user, userAuth, setCartItems } = Ctx();
  const { toggleItemQuantity } = Lctx();
  const handleCartActivitiesIfUser = (id: string, val: string) => {
    NProgress.start();
    patchData(
      `${cartUrl}/${id}`,
      {
        val,
      },
      {
        headers: {
          authorization: userAuth,
        },
      }
    ).then((data) => {
      NProgress.done();
      setCartItems(data.data.cart);
      val === "inc" && displaySuccessToast("Quantity increased successfully");
      val === "dec" && displaySuccessToast("Quantity decreased successfully");
      val === "del" && displaySuccessToast("Item removed successfully");
    });
  };

  if (user) {
    return (
      <div className="section_cartcont-box1">
        <a href="/">
          <img
            src={`https://omaye-foods-backend-production.up.railway.app/img/productss/${props.productId.coverImage}`}
            alt="product"
          />
          <div>
            <p>{props.productId.name}</p>
            <p>{props.productId.category}</p>
            <p>₦ {props.productId.price}</p>
          </div>
        </a>
        <div className="section_cartcont-box2">
          <div className="section_cartcont-hand">
            <p>
              <IoRemoveOutline
                onClick={() =>
                  handleCartActivitiesIfUser(props.idForProduct, "dec")
                }
              />
            </p>
            <p>{props.quantity}</p>
            <p>
              <IoAddOutline
                onClick={() =>
                  handleCartActivitiesIfUser(props.idForProduct, "inc")
                }
              />
            </p>
          </div>

          <button
            className="cart-pp"
            onClick={() =>
              handleCartActivitiesIfUser(props.idForProduct, "del")
            }
          >
            remove
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="section_cartcont-box1">
        <a href="/">
          <img
            src={`https://omaye-foods-backend-production.up.railway.app/img/productss/${props.coverImage}`}
            alt="prodcut"
          />
          <div>
            <p>{props.name}</p>
            <p>{props.category}</p>
            <p>₦ {props.price}</p>
          </div>
        </a>
        <div className="section_cartcont-box2">
          <div className="section_cartcont-hand">
            <p>
              <IoRemoveOutline
                onClick={() => toggleItemQuantity(props.id, "dec")}
              />
            </p>
            <p>{props.quantity}</p>
            <p>
              <IoAddOutline
                onClick={() => toggleItemQuantity(props.id, "inc")}
              />
            </p>
          </div>

          <button
            className="cart-pp"
            onClick={() => toggleItemQuantity(props.id, "del")}
          >
            remove
          </button>
        </div>
      </div>
    );
  }
};

export default CartContent;
