// import axios from "axios";
import Cookies from "js-cookie";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { displayErrorToast } from "../abstracts/toast";
// import { bookingsUrl } from "../utils/url";
// import Nprogress from "nprogress";
// import { Ctx } from "../contexts/GlobalContexts";
import { Select, Checkbox } from "antd";

const Shipping = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [payment, setPayment] = useState("");
  // const { setPayLink, cartItems } = Ctx();

  const cityRef = useRef<any>();
  const [region, setRegion] = useState<string>("lekki");
  const [city, setCity] = useState<string>();
  // const [code, setCode] = useState<string>();
  const handleChange = (value: string) => {
    setRegion(value);
  };
  const onChange = () => {
    setChecked1(false);
    setChecked(true);
    setPayment("Pos");
  };

  const onChange1 = () => {
    setChecked(false);
    setChecked1(true);
    setPayment("Cash on delivery");
  };

  const handleShipping = (e: any) => {
    e.preventDefault();
    if (
      !region ||
      !city ||
      // !code ||
      !cityRef.current?.value! ||
      !payment
    )
      return displayErrorToast("please fill in all the values");
    const shipping = [
      region,
      city,
      // code,
      cityRef.current?.value!,
      payment,
    ];
    Cookies.set("shippingDetails", JSON.stringify(shipping));
    // Nprogress.start();
    navigate("/purchase/placeorder");

    // axios
    //   .post(
    //     `${bookingsUrl}/initializetransaction`,
    //     {
    //       price,
    //     },
    //     {
    //       headers: {
    //         authorization: `Bearer ${JSON.parse(Cookies.get("SESSIONID")!)}`,
    //       },
    //     }
    //   )
    //   .then((data) => {
    //     Nprogress.done();
    //     setPayLink(data);
    //     Cookies.set("paylink", JSON.stringify(data));
    //     navigate("/purchase/placeorder");
    //   })
    //   .catch((err) => {
    //     Nprogress.done();
    //     displayErrorToast(
    //       "Something went wrong with the purchase, please try agin later"
    //     );
    //   });
  };
  return (
    <>
      <div className="shipping_box">
        <form className="shipping_form">
          <h1>shipping</h1>
          <div className="shipping_form-inputbox">
            <label htmlFor="location">city</label>
            <input
              type="text"
              disabled
              placeholder="lagos"
              name="location"
              value="lagos"
              //@ts-ignore
              ref={cityRef}
            />
          </div>

          <div className="shipping_form-inputbox">
            <label htmlFor="region">region</label>
            {/* <select name="region" onChange={(e) => setRegion(e.target.value)}>
              <option>lekki</option>
              <option>victoria island </option>
              <option>victoria garden city</option>
              <option>ikoyi</option>
              <option>ajah</option>
            </select> */}
            <Select
              defaultValue="lekki"
              style={{ width: 120 }}
              className="shippingselect"
              onChange={handleChange}
              options={[
                { value: "lekki", label: "lekki" },
                { value: "VI", label: "VI" },
                { value: "VGC", label: "VGC" },
                { value: "Ikoyi", label: "Ikoyi" },
                { value: "Oniru", label: "Oniru" },
              ]}
            />
          </div>
          <div className="shipping_form-inputbox">
            <label htmlFor="address">home address</label>
            <input
              type="text"
              name="address"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          {/* <div className="shipping_form-inputbox">
            <label htmlFor="code">postal code</label>
            <input
              type="number"
              name="code"
              onChange={(e) => setCode(e.target.value)}
            />
          </div> */}
        </form>
        <div className="shipping_box1">
          <h1>payment method</h1>
          <form>
            <h2>select method</h2>
            <div className="jumo">
              <Checkbox checked={checked} onChange={onChange} value="Pos">
                Pos
              </Checkbox>
            </div>
            <div className="jumo">
              <Checkbox
                checked={checked1}
                onChange={onChange1}
                value="Cash on delivery"
              >
                Cash on delivery
              </Checkbox>
            </div>
            <button onClick={handleShipping}>continue</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Shipping;
