import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import {
  IoPersonOutline,
  IoCartOutline,
  IoHomeOutline,
  IoBagOutline,
  IoInformationCircleOutline,
  // IoBookOutline,
  IoPhonePortraitOutline,
  IoMenu,
  IoClose,
} from "react-icons/io5";
export const Nav = () => {
  return (
    <nav className="nav">
      <Link to="/">
        <img src={logo} alt="logo" className="logo" />
      </Link>
      <ul>
        <li>
          <a href="/">home</a>
        </li>
        <li>
          <a href="/home">shop</a>
        </li>
        <li>
          <a href="/about">about</a>
        </li>
        {/* <li>
          <a href="/#">blog</a>
        </li> */}
        <li>
          <a href="/contact">contact</a>
        </li>
      </ul>
      <ul>
        <li>
          <a href="/cart">
            <IoCartOutline className="nav_icon" />
            cart
          </a>
        </li>
        <li>
          <a href="/account/profile">
            <IoPersonOutline className="nav_icon" />
            profile
          </a>
        </li>

        <li>
          <button className="nav_btn">download app</button>
        </li>
      </ul>
    </nav>
  );
};

export const MobileNav = () => {
  const [active, setActive] = useState<boolean>(false);
  const [leftChange, setLeftChange] = useState<boolean>(false);

  const handleDisplay = () => {
    setActive(!active);
  };
  const handleLeftChange = () => {
    setLeftChange(!leftChange);
  };
  return (
    <div>
      <div className="mb__drawer">
        <IoMenu onClick={handleLeftChange} className="mb__drawer-icon" />
      </div>

      <nav
        className={`mb-nav ${active && "mb-nav-active"} ${
          leftChange && "mb-nav--leftchange mb-nav-active"
        }`}
      >
        <IoMenu className="mb-nav-menu" onClick={handleDisplay} />
        <IoClose className="mb-nav-menu2" onClick={handleLeftChange} />
        <Link to="/">
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <ul>
          <li>
            <a href="/">
              <IoHomeOutline className="nav_icon" />
              <p className="mb-text">home</p>
            </a>
          </li>
          <li>
            <a href="/home">
              <IoBagOutline className="nav_icon" />
              <p className="mb-text">shop</p>
            </a>
          </li>
          <li>
            <a href="/about">
              <IoInformationCircleOutline className="nav_icon" />

              <p className="mb-text">about</p>
            </a>
          </li>
          {/* <li>
        <a href="/#">
          <IoBookOutline className="nav_icon" />

          <p className="mb-text">blog</p>
        </a>
      </li> */}
          <li>
            <a href="/contact">
              <IoPhonePortraitOutline className="nav_icon" />

              <p className="mb-text">contact</p>
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a href="/cart">
              <IoCartOutline className="nav_icon" />

              <p className="mb-text">cart</p>
            </a>
          </li>
          <li>
            <a href="/account/profile">
              <IoPersonOutline className="nav_icon" />

              <p className="mb-text">profile</p>
            </a>
          </li>
        </ul>
        <p>copyright © 2022 All rights reserved |</p>
      </nav>
    </div>
  );
};
