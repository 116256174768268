const OrderDetailsCard = ({
  img,
  name,
  price,
  qty,
}: {
  img: string;
  name: string;
  price: number;
  qty: number;
}) => {
  return (
    <div className="det-box--3-cont">
      <img
        alt="product"
        className="Order-detimg"
        src={`https://omaye-foods-backend-production.up.railway.app/img/productss/${img}`}
      />
      <div>
        <p>{name}</p>
        <p> Qty: {qty}</p>
        <p>₦ {price} </p>
      </div>
    </div>
  );
};

export default OrderDetailsCard;
