const formatName = (nameOfProduct: string) => {
  return nameOfProduct.length > 13
    ? nameOfProduct.split("").splice(0, 15).join("") + "..."
    : nameOfProduct;
};

export const getProductProperties = (data: any) => {
  const productDetails = data.data.data.data.map((product: any) => ({
    name: formatName(product.name),
    image: product.coverImage,
    category: product.category,
    id: product.id,
    price: product.price,
    slug: product.slug,
  }));
  return productDetails;
};

export const getOneProductProperty = (data: any) => {
  const productDetails = {
    name: data.data.data.product.name,
    image: data.data.data.product.coverImage,
    category: data.data.data.product.category,
    id: data.data.data.product.id,
    price: data.data.data.product.price,
    description: data.data.data.product.description,
  };
  return productDetails;
};
