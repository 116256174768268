import { useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { data, categoriesData } from "../assets/data/HomeData";
import Slider from "../components/Slider";

import { nextSlide, prevSlide } from "../abstracts/sliderFunctions";
import CategoryCard from "../components/CategoryCard";

import "nprogress/nprogress.css";
import Footer from "../components/Footer";
import TopProductsSection from "../components/TopProductsSection";
import SearchBar from "../components/SearchBar";
const Home = () => {
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [id, setId] = useState<any>();
  useEffect(() => {
    const id = setInterval(() => {
      nextSlide(slideIndex, data, setSlideIndex, id);
    }, 6000);
    setId(id);
  }, [slideIndex]);
  return (
    <>
      <SearchBar />
      <header className="header">
        <div
          className="header_icon header_icon-left"
          onClick={() => prevSlide(slideIndex, data, setSlideIndex, id)}
        >
          <IoIosArrowBack />
        </div>
        {data.map(({ bg, h1, h2, text, tems }, i) => (
          <Slider
            background={bg}
            h1={h1}
            h2={h2}
            text={text}
            key={i}
            index={i}
            curSlide={slideIndex}
            tems={tems}
          />
        ))}

        <div
          className="header_icon header_icon-right"
          onClick={() => nextSlide(slideIndex, data, setSlideIndex, id)}
        >
          <IoIosArrowForward />
        </div>
      </header>
      <section className="section_categories">
        <div className="section_categories--box1">
          <h2>Shop by category</h2>
          {/* <p>
            Select the category of products you want to make a purchase from
          </p> */}
        </div>

        <div className="section_categories--box2">
          {categoriesData.map(({ href, id, img, title }) => (
            <CategoryCard href={href} img={img} title={title} key={id} />
          ))}
        </div>
      </section>
      <TopProductsSection />
      <Footer />
    </>
  );
};

export default Home;
