import React from "react";
import Footer from "../components/Footer";
import about from "../assets/images/about.png";
import hour from "../assets/images/24-hours-support.png";
import cashback from "../assets/images/cashback.png";
import free from "../assets/images/free-delivery.png";
import premium from "../assets/images/premium-quality.png";
const About = () => {
  return (
    <main className="about">
      <section className="about__intro">
        <div className="about__intro-box">
          <img src={about} alt="about us" />

          <div className="about__intro-box-2">
            <h2>Know About Our Ecomerce Business, History</h2>
            <p>
              Mile12delivery is your go-to company for simple shopping and
              persuasion is ours. We have taken all necessary steps to provide
              you with only the finest farm products at the most competitive
              prices available. Our services guarantee to relieve you of your
              market stress .Mile12delivery will always be your best choice for
              fresh farm produce.
            </p>
            <a href="/contact">contact us</a>
          </div>
        </div>
      </section>
      <section className="about__details">
        <h2>Our Features</h2>
        <div className="about__details-container">
          <div className="about__details-box">
            <img src={free} alt="free delivery" />
            <h3>free delivery</h3>
            <p>
              Mile12delivery offers free delivery to your desired location on
              your first three transactions.
            </p>
          </div>
          <div className="about__details-box about__details-help">
            <img src={cashback} alt="free delivery" />
            <h3>100% cashback </h3>
            <p>
              Only yams that have been purchased and clearly show decomposition
              within 7 days are covered by our organization's 100 % cashback
              guarantee.
            </p>
          </div>
          <div className="about__details-box">
            <img src={premium} alt="free delivery" />
            <h3>quality product</h3>
            <p>
              Mile12delivery promises the cleanest farm produce possible. We
              make care to provide our customers with just the best quality.
            </p>
          </div>
          <div className="about__details-box">
            <img src={hour} alt="free delivery" />
            <h3>24/7 support</h3>
            <p>
              Mile12delivery ensures prompt customer service and is constantly
              accessible to it's clientele, To guarantee we provide the best
              service possible.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default About;
