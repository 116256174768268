import React from "react";
import cart from "../assets/images/cart.png";

const EmptyCart = () => {
  return (
    <section className="section_cart">
      <div className="section_cart-box1">
        <img src={cart} alt="empty cart" />
        <h2>Your Cart is empty</h2>
        <p>
          Looks like you have not added anything to your cart. Go ahead and
          <span>
            <a href="/products"> continue shopping </a>
          </span>
          or explore top products
        </p>
      </div>
    </section>
  );
};

export default EmptyCart;
