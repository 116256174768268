import React from "react";

const CategoryCard = ({
  href,
  img,
  title,
}: {
  href: string;
  img: string;
  title: string;
}) => {
  return (
    <a href={href} className="section_categories-container">
      <img src={img} alt={`${title}`} />
      {title}
    </a>
  );
};

export default CategoryCard;
