import BounceLoader from "react-spinners/BounceLoader";
import logo from "../assets/images/logo.svg";

const IsLoading = ({ isLoading }: { isLoading: boolean | undefined }) => {
  return (
    <div className="isloading">
      <BounceLoader color={"#059669"} loading={isLoading} size={60} />
      <div className="isloading__message">
        <p>Mile12delivery</p>

        <img src={logo} alt={logo} />
      </div>
    </div>
  );
};

export default IsLoading;
