import Nprogress from "nprogress";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Footer from "../components/Footer";
import ProfileNav from "../components/ProfileNav";
import { Ctx } from "../contexts/GlobalContexts";
import { emailRegex, phoneNumberRegex } from "../utils/config";
import { displayErrorToast, displaySuccessToast } from "../abstracts/toast";
import { useEffect, useState } from "react";
import IsLoading from "../components/IsLoading";
import { patchData } from "../abstracts/apiRequests";
import { updateUserUrl } from "../utils/url";
import { Select } from "antd";
const Profile = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const [userLocation, setUserLocation] = useState("lekki");

  const handleChange = (value: string) => {
    setUserLocation(value);
  };

  const { user, isLoggedIn, isLoading, userAuth, setUser } = Ctx();
  console.log(user);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/account/login", { state: { from: location } });
    }
  }, [isLoggedIn, navigate, location]);

  const userUpdateSchema = yup.object().shape({
    fullName: yup.string(),
    phoneNumber: yup
      .string()
      .matches(phoneNumberRegex, "Please put a valid phone number"),
    email: yup.string().matches(emailRegex, "Please put a valid email"),
  });

  const handleUserUpdate = async (values: any) => {
    const status = Object.values(values).map((el: any) => el.length === 0);
    if (!status.includes(false) && !userLocation) {
      return displayErrorToast("Please fill in at least one input");
    }
    if (!values.fullName) values.fullName = user.fullName;
    if (!values.email) values.email = user.email;
    if (!values.phoneNumber) values.phoneNumber = user.phoneNumber;
    values.location = userLocation;
    // return console.log(values);
    try {
      Nprogress.start();

      const response = await patchData(updateUserUrl, values, {
        headers: {
          authorization: userAuth,
        },
      });
      setUser(response.data.data);
      displaySuccessToast("Profile updated successfully");
      Nprogress.done();
    } catch (error) {
      Nprogress.done();
      console.log(error);
    }
  };

  return (
    <>
      {isLoading && <IsLoading isLoading={isLoading} />}
      {user && (
        <section className="section_profile">
          <ProfileNav />
          <div className="pbox">
            <Formik
              initialValues={{ fullName: "", email: "", phoneNumber: "" }}
              validationSchema={userUpdateSchema}
              onSubmit={handleUserUpdate}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="profile__inputdiv">
                    <label>Full Name</label>
                    <Field
                      type="text"
                      name="fullName"
                      placeholder={user.fullName}
                    />
                  </div>

                  <div className="profile__inputdiv">
                    <label>Email</label>
                    <Field name="email" placeholder={user.email} />
                    {errors.email && touched.email && (
                      <p className="form__error">{errors.email}</p>
                    )}
                  </div>
                  <div className="profile__inputdiv">
                    <label>PhoneNumber</label>
                    <Field
                      type="text"
                      name="phoneNumber"
                      placeholder={user.phoneNumber}
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                      <p className="form__error">{errors.phoneNumber}</p>
                    )}
                  </div>
                  <div className="profile__selectbox">
                    <label>Address</label>

                    <Select
                      className="profile__selectdrop"
                      defaultValue={user.location ? user.location : "lekki"}
                      onChange={handleChange}
                      options={[
                        { value: "lekki", label: "lekki" },
                        { value: "VI", label: "VI" },
                        { value: "VGC", label: "VGC" },
                        { value: "Ikoyi", label: "Ikoyi" },
                        { value: "Oniru", label: "Oniru" },
                      ]}
                    />
                  </div>

                  <button type="submit" className="section_profile--button">
                    update
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      )}

      <Footer />
    </>
  );
};

export default Profile;
