import React from "react";
import { IoBagCheckSharp } from "react-icons/io5";

const EmptyOrder = ({ message }: { message: string }) => {
  return (
    <div className="section_order--box-2">
      <IoBagCheckSharp className="order" />
      <p>You have no {message} orders </p>
      <a href="/products"> continue shopping</a>
    </div>
  );
};

export default EmptyOrder;
