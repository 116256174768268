// import { useState, useEffect } from "react";
import Nprogress from "nprogress";

// const useFetch = (url: string) => {
//   const [datum, setDatum] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const abort = new AbortController();
//     Nprogress.start();
//     fetch(url, { signal: abort.signal })
//       .then((res) => {
//         if (!res.ok) {
//           throw Error("Could not fetch the data from that resource");
//         }
//         return res.json();
//       })
//       .then(({ data }) => {
//         // console.log(data);
//         setDatum(data);
//         setIsLoading(false);
//         setError(null);
//         Nprogress.done();
//       })
//       .catch((err) => {
//         if (err.name === "AbortError") {
//           // console.log(err.name);
//         } else {
//           Nprogress.done();

//           console.log(err.message);
//           setError(err.message);
//           setIsLoading(false);
//         }
//       });
//     return () => abort.abort();
//   }, [url]);
//   return { datum, isLoading, error };
// };

// export default useFetch;

import axios from "axios";
import { useState, useEffect } from "react";

const useFetch = (url: string, token?: string) => {
  const [datum, setDatum] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean | string>(false);

  useEffect(() => {
    if (token) {
      Nprogress.start();
      setIsLoading(true);
      axios
        .get(url, { headers: { authorization: `Bearer ${token}` } })
        .then((data) => {
          Nprogress.done();
          setIsLoading(false);

          setDatum(data);
        })
        .catch((err) => {
          Nprogress.done();
          setError(err.response.data.message);
          setIsLoading(false);
        });
    }
  }, [url, token]);
  return { datum, isLoading, error };
};

export default useFetch;
