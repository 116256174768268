import React from "react";
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoWhatsapp,
  IoLogoInstagram,
} from "react-icons/io5";

import logo from "../assets/images/logo.svg";
import apple from "../assets/images/apple.png";
import google from "../assets/images/google.png";
const Footer = () => {
  return (
    <footer>
      <div className="footer_box">
        <div className="footer_box--1">
          <a href="/" className="footer_box-head">
            Mile12Delivery <img src={logo} alt="logo" />
          </a>
          <p>
            The customer is at the heart of our unique business model. Get our
            mobile apps from the apple and playstore respectively using the
            links below. <br />
            <span className="footer__help">coming soon...</span>
          </p>
          <div className="flex">
            <a href="/#" className="app mr-8">
              <img src={apple} alt="appstore" />
            </a>
            <a href="/#" className="app">
              <img src={google} alt="playstore" />
            </a>
            {/* <a href="/#"></a> */}
          </div>
        </div>
        <div className="footer_box--2">
          <h2>Products</h2>
          <ul>
            <li>
              <a href="/products?category=fresh farm produce">fruits</a>
            </li>
            <li>
              <a href="/products?category=groceries">grains</a>
            </li>
            <li>
              <a href="/products?category=drinks">african food</a>
            </li>
            <li>
              <a href="/products?category=vegetables">oils</a>
            </li>
            <li>
              <a href="/products?category=cosmetics and deodorants">
                tuber crops
              </a>
            </li>
            <li>
              <a href="/products?category=african kitchen">vegetables</a>
            </li>
          </ul>
        </div>
        <div className="footer_box--3">
          <ul>
            <h2>links</h2>
            <li>
              <a href="/contact">contact us</a>
            </li>
            <li>
              <a href="/about">payment method</a>
            </li>
            <li>
              <a href="/about">delivery</a>
            </li>
            <li>
              <a href="/about">return policy</a>
            </li>
          </ul>
        </div>
        <div className="footer_box--4">
          <h2> socials</h2>
          <ul>
            <li>
              <a
                href="https://facebook.com/mile12delivery"
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoFacebook size={"2.5rem"} />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/mile12delivery"
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoTwitter size={"2.5rem"} />
              </a>
            </li>
            <li>
              <a
                href="https://api.whatsapp.com/send/?phone=2348147310446&text&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoWhatsapp size={"2.5rem"} />
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/_mile12delivery"
                target="_blank"
                rel="noreferrer"
              >
                <IoLogoInstagram size={"2.5rem"} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
