import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { displayErrorToast, displaySuccessToast } from "../abstracts/toast";
import Nprogress from "nprogress";
import { useEffect } from "react";
import { bookingsUrl, cartUrl } from "../utils/url";
// import { Ctx } from "../contexts/GlobalContexts";
import { deleteData } from "../abstracts/apiRequests";

const Success = () => {
  // const { payLink } = Ctx();
  const navigate = useNavigate();
  const shipping = JSON.parse(Cookies.get("shippingDetails")!);
  const cart = JSON.parse(Cookies.get("purchaseCart")!);
  const order = cart.cart;
  const price = cart.subTotal;
  // console.log(price, bookingsUrl);

  // const { reference } = payLink.data.data.data;

  useEffect(() => {
    setTimeout(() => {
      axios
        .post(
          // `${bookingsUrl}/${reference}`,
          `${bookingsUrl}/${price}`,
          {
            order,
            address: shipping[0],
            city: shipping[1],
            postal: shipping[2],
            region: shipping[3],
          },
          {
            headers: {
              authorization: `Bearer ${JSON.parse(Cookies.get("SESSIONID")!)}`,
            },
          }
        )
        .then(() => {
          Nprogress.done();
          displaySuccessToast("verification successful");
          Cookies.remove("purchaseCart");
          deleteData(cartUrl, {
            headers: {
              authorization: `Bearer ${JSON.parse(Cookies.get("SESSIONID")!)}`,
            },
          }).then(() => {
            setTimeout(() => {
              navigate("/", { replace: true });
            }, 3000);
          });
        })
        .catch((err) => {
          Nprogress.done();
          console.log(err);
          displayErrorToast(
            "Something went wrong with the purchase, please try agin later"
          );
        });
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="success_order">
      <div>
        <h1>success</h1>
        <p>order placed successfully</p>
      </div>
    </div>
  );
};

export default Success;
