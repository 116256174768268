import { IoSearchSharp } from "react-icons/io5";
import useFetch from "../customs/useFetch";
import { generalUrl } from "../utils/url";
import { useEffect, useState } from "react";
import { Ctx } from "../contexts/GlobalContexts";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  let url = `${generalUrl}/products/`;
  const { handleSearchResults, setSearchProducts, setSearch } = Ctx();
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [data, setData] = useState([]);
  const { datum } = useFetch(url, "token");
  const navigate = useNavigate();

  useEffect(() => {
    if (datum) {
      setData(datum.data.data.data);
      const filteredData = data.filter((product: any) => {
        return product.name.toLowerCase().includes(searchInput.toLowerCase());
      });
      console.log(filteredData);
      handleSearchResults(filteredData);
      //   setSearchProducts(filteredData);
      setFilteredResults(filteredData);
    }
  }, [searchInput, datum, data, handleSearchResults]);

  const handleNavigate = (e: any) => {
    e.preventDefault();
    navigate("/products/search");
    setSearchProducts(filteredResults);
    setSearch(searchInput);
    setSearchInput("");
  };

  return (
    <div className="search__intro">
      <div className="search__container">
        <div className="search__boxinner">
          <div className="search__inputbox">
            <IoSearchSharp className="searchicon" />
            <input
              value={searchInput}
              placeholder="search for anything"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>

          <button onClick={handleNavigate} className="search__btn">
            Search
          </button>
        </div>
      </div>
      {/* <div className="search__results"></div> */}
    </div>
  );
};

export default SearchBar;
