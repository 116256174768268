import Footer from "../components/Footer";
import ProfileNav from "../components/ProfileNav";
import { Formik, Form, Field } from "formik";
import { displayErrorToast, displaySuccessToast } from "../abstracts/toast";
import Nprogress from "nprogress";
import * as yup from "yup";
import { updateUserPasswordUrl } from "../utils/url";
import { patchData } from "../abstracts/apiRequests";
import { Ctx } from "../contexts/GlobalContexts";

const Password = () => {
  const { userAuth, logout } = Ctx();

  const userPasswordSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .length(8, "Password must be at leat 8 characters"),
    password: yup.string().length(8, "Password must be at leat 8 characters"),
    confirmPassword: yup
      .string()
      .length(8, "Password must be at leat 8 characters"),
  });

  const handleUserPasswordUpdate = async (values: any) => {
    const status = Object.values(values).map((el: any) => el.length === 0);
    console.log(values);
    if (status.includes(true)) {
      return displayErrorToast("Please fill in all the inputs");
    }

    try {
      Nprogress.start();

      await patchData(updateUserPasswordUrl, values, {
        headers: {
          authorization: userAuth,
        },
      });

      displaySuccessToast("Password updated successfully");
      logout();
      Nprogress.done();
    } catch (error: any) {
      Nprogress.done();
      displayErrorToast(error.response.data.message);
    }
  };
  return (
    <>
      <section className="section_profile">
        <ProfileNav />
        <div className="pbox">
          <Formik
            initialValues={{
              currentPassword: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={userPasswordSchema}
            onSubmit={handleUserPasswordUpdate}
          >
            {({ errors, touched }) => (
              <Form>
                <div>
                  <label>Current Password</label>
                  <Field type="text" name="currentPassword" />
                  {errors.currentPassword && touched.currentPassword && (
                    <p className="form__error">{errors.currentPassword}</p>
                  )}
                </div>
                <div>
                  <label>New Password</label>
                  <Field type="text" name="password" />
                  {errors.password && touched.password && (
                    <p className="form__error">{errors.password}</p>
                  )}
                </div>
                <div>
                  <label>Confirm Password</label>
                  <Field type="text" name="confirmPassword" />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <p className="form__error">{errors.confirmPassword}</p>
                  )}
                </div>
                <button type="submit" className="section_profile--button">
                  update
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Password;
