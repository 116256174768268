import axios from "axios";
import React from "react";
import { displayErrorToast, displaySuccessToast } from "../abstracts/toast";
import { Ctx } from "../contexts/GlobalContexts";
import { savedUrl } from "../utils/url";

const SavedItem = (props: any) => {
  const { userAuth, setSavedItems } = Ctx();

  const removeSaveHandler = (e: any, id: string) => {
    e.preventDefault();
    axios
      .delete(`${savedUrl}/${id}`, {
        headers: {
          authorization: userAuth,
        },
      })
      .then((data) => {
        setSavedItems(data.data.saved.items);
        displaySuccessToast("item removed successfully");
      })
      .catch((err) => {
        displayErrorToast("something went wrong");
      });
  };
  return (
    <div className="section_order">
      <img
        src={`https://omaye-foods-backend-production.up.railway.app/img/productss/${props.productId.coverImage}`}
        alt="product"
      />
      <div>
        <p>{props.productId.name}</p>
        <p className="p">₦ {props.productId.price}</p>
        <p className="p">{props.productId.category}</p>
        {/* <p>On 25-04-2022</p> */}
      </div>
      <div className="saved-box">
        <a href={`/products/${props.productId.slug}`}>see product</a>
        <a
          href={`/#`}
          className="saved-remove"
          onClick={(e) => removeSaveHandler(e, props.idForProduct)}
        >
          remove item
        </a>
      </div>
    </div>
  );
};

export default SavedItem;
