import Cookies from "js-cookie";
import CheckoutCard from "../components/CheckoutCard";
import Footer from "../components/Footer";
import { Ctx } from "../contexts/GlobalContexts";

const Checkout = () => {
  const shipping = JSON.parse(Cookies.get("shippingDetails")!);

  // const { payLink, cartItems } = Ctx();
  const { cartItems } = Ctx();
  return (
    <>
      <div className="checkout_container">
        {cartItems && (
          <div className="checkout_container-box1">
            <h2>shipping details</h2>
            <div>
              <p>
                address : <span> {shipping[0]}</span>
              </p>
              <p>
                city :<span> {shipping[1]}</span>
              </p>
              {/* <p>
                postal :<span> {shipping[2]}</span>
              </p> */}
              <p>
                region :<span> {shipping[2]}</span>
              </p>
            </div>
          </div>
        )}
        {cartItems && (
          <div className="checkout_container-box1">
            <h2>payment method</h2>
            <div>
              <p>
                method : <span> {shipping[3]}</span>
              </p>
            </div>
          </div>
        )}
        {cartItems && (
          <div className="checkout_container-box2">
            <h2>order items</h2>
            {cartItems.cart.map((item: any, i: number) => (
              <CheckoutCard
                qty={item.quantity}
                total={item.totalPrice}
                price={item.price}
                name={item.productId.name}
                img={item.productId.coverImage}
                key={item.idForProduct}
              />
            ))}
          </div>
        )}

        {cartItems && (
          <div className="checkout_container-box3">
            <h2>total</h2>
            <div>
              <p>purchase price:</p>
              <p>₦{cartItems.subTotal} </p>
            </div>
            <div>
              <p>delivery price:</p>
              <p>₦ 1000 </p>
            </div>
            <div>
              <p>total price:</p>
              <p>₦ {cartItems.subTotal + 1000}</p>
            </div>
            <button>
              {/* if there is paystack use this */}
              {/* <a href={payLink.data.data.data.authorization_url}>place order</a> */}
              <a href="/purchase/confirmation/success">place order</a>
            </button>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
