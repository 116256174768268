import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { cartUrl, savedUrl, userUrl } from "../utils/url";
import { getData } from "../abstracts/apiRequests";
import { useNavigate } from "react-router-dom";
import { displaySuccessToast } from "../abstracts/toast";

const context = React.createContext<any>(null!);

const GlobalContexts = ({ children }: any) => {
  const navigate = useNavigate();
  const [savedItems, setSavedItems] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cartIsLoading, setCartIsLoading] = useState(false);
  const [savedIsLoading, setSavedIsLoading] = useState(false);
  const [searchProducts, setSearchProducts] = useState(
    Cookies.get("searchResults")
      ? JSON.parse(Cookies.get("searchResults")!)
      : null
  );
  const [search, setSearch] = useState("");
  const [payLink, setPayLink] = useState(
    Cookies.get("paylink") ? JSON.parse(Cookies.get("paylink")!) : null
  );
  const [user, setUser] = useState<any>(null);
  const [qty, setQty] = useState(1);

  const [isLoggedIn, setisLoggedIn] = useState<boolean>(
    Cookies.get("SESSIONID") ? true : false
  );

  const userAuth = Cookies.get("SESSIONID")
    ? `Bearer ${JSON.parse(Cookies.get("SESSIONID")!)}`
    : false;
  const inc = () => {
    setQty((prev) => prev + 1);
  };

  const logout = () => {
    Cookies.remove("SESSIONID");
    setUser(null);
    setisLoggedIn(false);
    navigate("/");
    displaySuccessToast("Successfully logged out");
  };

  const dec = () => {
    setQty((prev) => {
      if (prev - 1 < 1) return 1;
      return prev - 1;
    });
  };
  useEffect(() => {
    if (user) {
      // get saved items when user is authenticated
      setSavedIsLoading(true);
      getData(savedUrl, {
        headers: {
          authorization: userAuth,
        },
      }).then((data) => {
        setSavedIsLoading(false);
        // console.log(data.data.data.saved);
        setSavedItems(data.data.data.saved.items);
        // setSavedItems(data.data.data.saved);
      });

      // get cart items when user is authenticated
      setCartIsLoading(true);
      getData(cartUrl, {
        headers: {
          authorization: userAuth,
        },
      }).then((data) => {
        setCartIsLoading(false);
        setCartItems(data.data.data.cart);
      });
    }
  }, [userAuth, user]);

  useEffect(() => {
    // get loggged in user
    setIsLoading(true);
    if (isLoggedIn) {
      getData(userUrl, {
        headers: {
          authorization: userAuth,
        },
      }).then((data) => {
        setIsLoading(false);
        setUser(data.data.data.user);
      });
      console.log("object");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  const handleSearchResults = (data: any) => {
    console.log(data, "popoopo");
    Cookies.remove("searchResults");
    Cookies.set("searchResults", JSON.stringify(data));
    console.log(searchProducts);
    // setSearchProducts(data);
  };

  return (
    <context.Provider
      value={{
        user,
        isLoggedIn,
        setisLoggedIn,
        setSavedItems,
        savedItems,
        payLink,
        setPayLink,
        setUser,
        qty,
        inc,
        dec,
        userAuth,
        cartItems,
        setCartItems,
        logout,
        isLoading,
        cartIsLoading,
        savedIsLoading,
        searchProducts,
        handleSearchResults,
        setSearchProducts,
        setSearch,
        search,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const Ctx = () => useContext(context);
export default GlobalContexts;
