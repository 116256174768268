// import React, { useState } from "react";
import { IoAddOutline, IoHeart } from "react-icons/io5";
import { Ctx } from "../contexts/GlobalContexts";
import Nprogress from "nprogress";
import { cartUrl, savedUrl, generalUrl } from "../utils/url";
import Cookies from "js-cookie";
import { displayErrorToast, displaySuccessToast } from "../abstracts/toast";
import { postData } from "../abstracts/apiRequests";
import { Lctx } from "../contexts/LocalContexts";
import NProgress from "nprogress";
import useFetch from "../customs/useFetch";
import { useEffect, useState } from "react";
import { getOneProductProperty } from "../helpers/productDestructure";

const ProductCard = (props) => {
  const { setSavedItems, savedItems, user } = Ctx();
  const { onAdd } = Lctx();
  const [productDetails, setProductDetails] = useState();

  const { datum: productData } = useFetch(
    `${generalUrl}/products/helper/${props.link}`,
    "huyuh"
  );
  useEffect(() => {
    if (productData) {
      setProductDetails(getOneProductProperty(productData));
    }
  }, [productData]);

  // productDetails && console.log(productDetails);

  const handleSave = (e, price, productId) => {
    e.preventDefault();
    if (!user) return displayErrorToast("Please login to save items");
    Nprogress.start();
    postData(
      savedUrl,
      {
        price,
        productId,
      },
      {
        headers: {
          authorization: `Bearer ${JSON.parse(Cookies.get("SESSIONID"))}`,
        },
      }
    ).then((data) => {
      Nprogress.done();
      if (data.data.status === "successs") {
        displaySuccessToast(data.data.message);
      }
      if (data.data.status === "success") {
        displaySuccessToast(data.data.message);
      }
      setSavedItems(data.data.data.saved.items);
    });
  };

  let color = "green";
  savedItems &&
    savedItems.forEach((item) => {
      if (item.idForProduct === props.id) {
        color = "red";
      }
      return;
    });

  const addToCart = (product, qty) => {
    NProgress.start();
    postData(
      cartUrl,
      {
        productId: product.id,
        price: product.price,
        quantity: qty,
      },
      {
        headers: {
          authorization: `Bearer ${JSON.parse(Cookies.get("SESSIONID"))}`,
        },
      }
    ).then(() => {
      NProgress.done();
      displaySuccessToast("Product added successfully");
    });
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    user ? addToCart(productDetails, 1) : onAdd(productDetails, 1);
  };

  return (
    <a href={`/products/${props.link}`} className="product__container">
      <div className="product__box">
        <div className="img__box">
          <IoHeart
            className="like"
            onClick={(e) => handleSave(e, props.price, props.id)}
            style={{ color: color }}
          />
          <img
            src={`https://omaye-foods-backend-production.up.railway.app/img/productss/${props.coverImage}`}
            alt="product"
            className="product__img"
          />
          <p className="product__price">₦ {props.price}</p>
        </div>
        <div className="details__box">
          <div>
            <h2 className="text-xl">{props.name}</h2>
            <p className="">{props.category.toUpperCase()}</p>
          </div>
          <IoAddOutline
            className="quick-add"
            title="quick add"
            onClick={handleAddToCart}
          />
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
