import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getData } from "../abstracts/apiRequests";
import Footer from "../components/Footer";
import OrderDetailsCard from "../components/OrderDetailsCard";
import ProfileNav from "../components/ProfileNav";
import { Ctx } from "../contexts/GlobalContexts";
import { bookingsUrl } from "../utils/url";

const OrderDetails = () => {
  const { id } = useParams();

  const [booking, setBooking] = useState<any>(null);
  const { userAuth } = Ctx();
  useEffect(() => {
    getData(`${bookingsUrl}/${id}`, {
      headers: {
        authorization: userAuth,
      },
    })
      .then((data) => {
        console.log(data.data.data.booking);
        const { booking } = data.data.data;
        setBooking(booking);
      })
      .catch((err) => console.log(err));
  }, [userAuth, id]);
  return (
    <>
      {booking && (
        <section className="section_profile">
          <ProfileNav />
          <div className="det-box">
            <div className="det-box--1">
              <h2 className="det-box--heading">order details</h2>
            </div>
            <div className="det-box--2">
              <h3>order {booking.transactionReference}</h3>
              <p className="p">{booking.order.length} items</p>
              <p className="p">
                placed on {new Date(booking.createdAt).toDateString()}
              </p>
              <p className="p">total : ₦ {booking.amount}</p>
            </div>
            <div className="det-box--3">
              <h2>items purchased</h2>
              {booking.order.map((item: any) => (
                <OrderDetailsCard
                  key={item.productId.id}
                  img={item.productId.coverImage}
                  name={item.productId.name}
                  price={item.productId.price}
                  qty={item.quantity}
                />
              ))}
            </div>
            <div className="det-box--4">
              <div className="det-box--4-1">
                <div>
                  <h2>Payment method</h2>
                  <p>Method: Paid with card</p>
                </div>
                <div>
                  <h2>Payment Details</h2>
                  <p>Amount for Items: ₦ {booking.amount}</p>
                  <p>Amount for Delivery: ₦ 1000</p>
                </div>
              </div>

              <div className="det-box--4-2">
                {/* <div>
                  <h2>Delivery Info</h2>
                  <p>paid with card</p>
                </div> */}
                <div>
                  <h2>Delivery Location</h2>
                  <p>town: {booking.address}</p>
                  <p>city: {booking.city}</p>
                  <p>postal: {booking.postal}</p>
                  <p>region: {booking.region}</p>
                </div>
                {/* <div>
                  <h2>Delivery Location</h2>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default OrderDetails;
