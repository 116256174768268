import axios, { AxiosRequestConfig } from "axios";

export const getData = async (
  url: string,
  headers?: AxiosRequestConfig<any>
) => {
  const data = await axios.get(url, headers);
  return data;
};

export const postData = async (
  url: string,
  body: any,
  headers?: AxiosRequestConfig<any>
) => {
  const data = await axios.post(url, body, headers);
  return data;
};

export const patchData = async (
  url: string,
  body: any,
  headers?: AxiosRequestConfig<any>
) => {
  const data = await axios.patch(url, body, headers);
  return data;
};

export const deleteData = async (
  url: string,
  headers?: AxiosRequestConfig<any>
) => {
  const data = await axios.delete(url, headers);
  return data;
};
